import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';

import { ROOM_MODE } from '../../../../shared/constants';

import { useRealtime } from '../../../../contexts/realtime';

import SpyUsersCounter from './SpyUsersCounter';

//icons
import IconVip from '../../../../public/assets/images/icons/vip.svg';
import IconPaused from '../../../../public/assets/images/icons/pause.svg';

const RoomMode = ({ performer, useShortText, roomOffline, showCounter }) => {
  const { formatMessage: t } = useIntl();
  const { userCounter, rMode, roomPaused } = useRealtime();
  const { id } = performer;

  const [free, setFree] = useState(0);
  const [paying, setPaying] = useState(0);
  const [spyUsers, setSpyUsers] = useState(0);
  const [mode, setMode] = useState(null);
  const [showPaused, setShowPaused] = useState(null);

  //set initial value for paused
  useEffect(() => {
    if (showPaused === null) {
      setShowPaused(Boolean(performer.paused));
    }
  }, [showPaused, performer.paused]);
  //update in real time showPaused
  useEffect(() => {
    if (roomPaused?.id !== id) return; //check if is performer instance
    setShowPaused(Boolean(roomPaused.paused));
  }, [roomPaused, performer.id]);

  useEffect(() => {
    if (!userCounter) return;
    if (userCounter.id !== id) return;
    if (userCounter.key === 'freeusers') {
      setFree(userCounter.value > 0 ? userCounter.value : 0);
    } else {
      setSpyUsers(userCounter.spyusers > 0 ? userCounter.spyusers : 0);
      setPaying(userCounter.value > 0 ? userCounter.value : 0);
    }
  }, [userCounter, id]);
  useEffect(() => {
    setMode(performer.roomMode);
    setFree(parseInt(performer.freeusers) > 0 ? performer.freeusers : 0);
    setPaying(parseInt(performer.users) > 0 ? performer.users : 0);
    setSpyUsers(performer.spyusers > 0 ? performer.spyusers : 0);
  }, [performer]);
  useEffect(() => {
    if (!rMode) return;
    if (rMode.id !== id) return;
    if (rMode.key === 'roomMode') {
      setMode(rMode.value);
    } else {
      setMode(rMode.value);
    }
  }, [rMode, id]);

  return (
    <div className="text-sm whitespace-nowrap">
      {!roomOffline && (
        <>
          {mode === 'freechat' && !Boolean(showPaused) && (
            <div className="flex text-green-500 gap-1 items-center">
              <div className="flex gap-1 items-center bg-green-600 text-white p-1 text-xs rounded-lg">
                {useShortText ? 'FC' : 'Free chat'}
                {showCounter && <span>({free})</span>}
              </div>
              <SpyUsersCounter spyUsers={spyUsers} />
            </div>
          )}
          {mode === ROOM_MODE.PRIVATE && (
            <div className="flex gap-1 items-center">
              {Boolean(showPaused) && (
                <>
                  <div className="flex gap-1 items-center bg-red-600 text-white p-1 text-xs rounded-lg">
                    Paused
                  </div>
                </>
              )}
              <div className="flex gap-1 items-center">
                <span className="bg-orange-500 text-white p-1 text-xs rounded-lg">
                  {/* {useShortText ? 'GC ' : 'Group chat '} */}
                  {t({ id: 'room_mode_private' })}{' '}
                  {showCounter && (
                    <span>({paying > 0 ? paying - spyUsers : 0})</span>
                  )}
                </span>

                {paying > 0 && (
                  <span className="bg-blue-100 text-black border border-blue-600 p-1 text-xs rounded-lg">
                    {performer.credits_group} cr./min.
                  </span>
                )}

                <SpyUsersCounter spyUsers={spyUsers} />
              </div>
            </div>
          )}

          {mode === ROOM_MODE.VIP && (
            <span className="flex gap-1 items-center text-yellow-700">
              {Boolean(showPaused) && (
                <>
                  <IconPaused className="text-red-600 h-5 w-5" />
                </>
              )}
              <div className="flex gap-1 items-center bg-yellow-600 text-white px-2 py-1 text-xs rounded-lg">
                {!Boolean(showPaused) && (
                  <>
                    <IconVip className="h-4 w-4" />
                  </>
                )}
                Vip
              </div>
              <span className="bg-blue-100 text-black border border-blue-600 p-1 text-xs rounded-lg">
                {performer.credits_vip} cr./min.
              </span>
            </span>
          )}
        </>
      )}
      {roomOffline && (
        <div className="flex text-red-500 gap-1 items-center font-extrabold">
          Offline
        </div>
      )}
    </div>
  );
};

export default RoomMode;
